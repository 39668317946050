window.setCookie = (name,value,days) => {
	var expires = "";
	if (days) {
		var date = new Date();
		date.setTime(date.getTime() + (days*24*60*60*1000));
		expires = "; expires=" + date.toUTCString();
	}
	document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
window.getCookie = (name) => {
	var nameEQ = name + "=";
	var ca = document.cookie.split(';');
	for(var i=0;i < ca.length;i++) {
		var c = ca[i];
		while (c.charAt(0)==' ') c = c.substring(1,c.length);
		if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
	}
	return null;
}

import './bootstrap'
import Alpine from 'alpinejs'
window.Alpine = Alpine
Alpine.start()

import { createApp } from 'vue/dist/vue.esm-bundler'
const cards = createApp({})

import Cards from './components/Cards.vue'
cards.component('frontend-cards', Cards)

if( document.getElementById('cards-container') ) {
	cards.mount('#cards-container')
}

import BlogPosts from './blog/components/BlogPosts.vue'
import prismic from "./blog/prismic.js"
const blog = createApp({
	components: {
		'blog-posts': BlogPosts
	}
})
if( document.getElementById('blog-posts') ) {
	blog.use(prismic).mount('#blog-posts')
}

import Glide from '@glidejs/glide'

function GlideAutoHeight(Glide, Components, Events) {
    const Component = {
        mount() {
            if (!Glide.settings.autoHeight) return;
            Components.Html.track.style.transition = 'height 200ms ease-in-out';
            this.updateTrackHeight();
        },

        updateTrackHeight() {
            if (!Glide.settings.autoHeight) return;

            const activeSlides = Components.Html.slides.filter((slide, index) => {
                return (index >= Glide.index && index <= (Glide.index-1) + Glide.settings.perView);
            });

            const newMaxHeight = activeSlides.reduce((maxHeight, slide) => {
                return Math.max(maxHeight, slide.offsetHeight);
            }, 0);

            const glideTrack = Components.Html.track;
            if (newMaxHeight !== glideTrack.offsetHeight) {
                glideTrack.style.height = `${newMaxHeight}px`;
            }
        },
    };

    Events.on('run', () => {Component.updateTrackHeight();});
    Events.on('update', () => {Component.updateTrackHeight();});
    Events.on('resize', () => {Component.updateTrackHeight();});

    return Component;
}

document.querySelectorAll('[data-glide]').forEach(elem => {
	let settings = elem.dataset.glide || null
	if( settings ) {
		settings = JSON.parse(settings)
	} else {
		settings = {}
	}
	new Glide(elem, settings).mount({
		 GlideAutoHeight: GlideAutoHeight
	})
})

window.addEventListener('resize', gtShowHideScrollButtons)

function gtShowHideScrollButtons() {
	document.querySelectorAll('[data-scroll]').forEach(elem => {
		let track = document.querySelector(elem.dataset.scroll)
		if( track ) {
			if( track.scrollWidth <= track.clientWidth ) {
				elem.style.display = 'none'
			} else {
				elem.style.removeProperty('display')
			}
		}
	})
}

gtShowHideScrollButtons()

document.querySelectorAll('[data-scroll] > button').forEach(elem => {
	let track = document.querySelector(elem.parentElement.dataset.scroll)
	if( track ) {
		elem.addEventListener('click', e => {
			e.preventDefault()
			track.scrollLeft += typeof elem.dataset.left !== 'undefined' ? -200 : 200
		})
	}
})

const countryPrompt = (() => {

	let currentLocation,
		browserTimezone,
		country,
		elem = document.querySelector('[data-country-prompt]'),
		close = document.querySelector('[data-country-prompt-close]'),
		cookieName = 'givetastic_hide_location_prompt',

	maybeShowPrompt = () => {
		if( ! country ) return
		if( country.enabled && country.code.toLowerCase() !== currentLocation ) {
			document.querySelector('[data-country-prompt-name]').innerText = country.name
			document.querySelector('[data-country-prompt-input]').value = country.code
			elem.classList.remove('hidden')
		}
	},

	getCurrentLocation = () => {
		const meta = document.querySelector('meta[name="country"]')
		if( meta ) {
			currentLocation = meta.content
		}
	},

	getBrowserLocation = () => {
		browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
	},

	getCountryFromTimezone = () => {
		return axios.get('/api/timezones/get-country-from-timezone', {params: {
			timezone: browserTimezone
		}}).then(response => {
			country = response.data
		}).catch(error => {
			console.error(error)
		})
	},

	initEvents = () => {
		close.addEventListener('click', (e) => {
			e.preventDefault()
			window.setCookie(cookieName, 1, 30)
			elem.classList.add('hidden')
		})
	},

	init = async () => {
		if( ! window.getCookie(cookieName) && elem ) {
			initEvents()
			getCurrentLocation()
			getBrowserLocation()
			await getCountryFromTimezone()
			maybeShowPrompt()
		}
	};

	return {init: init};

})();

countryPrompt.init()

const modalButtons = (() => {

	const init = () => {
		const buttons = document.querySelectorAll('[data-modal-trigger]')
		buttons.forEach(button => {
			button.addEventListener('click', e => {
				e.preventDefault()
				const modal = button.dataset.modalTrigger
				const event = new CustomEvent('gt-modal-open', { detail: modal });
				window.dispatchEvent(event)
			})
		})
	};

	return {init: init};

})();

modalButtons.init();