<template>

	<div>
		<scroll-buttons selector="#occasions-track"></scroll-buttons>
		<nav class="mb-20">
			<ul class="flex items-center whitespace-nowrap overflow-x-auto" id="occasions-track">
				<template v-for="o in occasions">
					<li>
						<a 
							href="#"
							@click.prevent="selectOccasion(o)"
							v-html="o.name"
							:class="{'bg-primary-600 text-white': o.slug == occasion}"
							class="block px-5 py-2.5 rounded-full font-medium shrink-0 lg:px-8 lg:py-3 lg:text-lg"
						></a>
					</li>
				</template>
			</ul>
		</nav>
		<div class="relative h-[475px]" id="cards">
			<div class="absolute top-0 bottom-0 -left-1/2 -right-1/2 w-[200%] bg-primary-25 z-20 flex items-center justify-center" v-show="loading">
				<loading></loading>
			</div>
			<div class="absolute left-0 top-0 h-full" data-glide-container>
				<div class="glide" id="cards-carousel">
					<div data-glide-el="track" class="glide__track">
						<ul class="glide__slides">
							<li class="glide__slide" v-for="c in cardTypes">
								<img class="w-full max-w-[345px]" :src="c.image">
							</li>
						</ul>
						<div class="glide__arrows" data-glide-el="controls">
							<button class="glide__arrow glide__arrow--left" data-glide-dir="<">
								<icon type="arrow-left" class="stroke-primary-600" size="lg"></icon>
							</button>
							<button class="glide__arrow glide__arrow--right" data-glide-dir=">">
								<icon type="arrow-right" class="stroke-primary-600" size="lg"></icon>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	
</template>

<script>

	import Glide from '@glidejs/glide'
	import Icon from './Icon.vue'
	import ScrollButtons from './ScrollButtons.vue'
	import Loading from './Loading.vue'

	export default {

		props: ['selectedOccasion'],

		components: {
			'icon': Icon,
			'loading': Loading,
			'scroll-buttons': ScrollButtons
		},

		data() {

			return {
				occasions: [],
				cardTypes: [],
				occasion: null,
				loading: false,
				cardPreview: false,
				container: null,
				carousel: null,
				glideSettings: {
					perView: 4, 
					breakpoints: {
						768: {
							perView: 3
						},
						640: {
							perView: 2
						},
						520: {
							perView: 1
						},
					},
					type: 'carousel', 
					peek: {
						after: 100, 
						before: 0
					}
				}
			}
		},

		mounted() {
			this.setDimensions()
			this.getOccasions()
			window.addEventListener('resize', () => {
				this.setDimensions()
			})
		},

		methods: {

			initCarousel() {
				if( this.carousel ) {
					this.carousel.destroy()
				}
				const elem = document.getElementById('cards-carousel')
				this.carousel = new Glide(elem, this.glideSettings).mount()
			},

			setDimensions() {
				this.container = document.getElementById('cards')
				let windowWidth = parseInt(window.innerWidth)
				let containerWidth = parseInt(this.container.clientWidth)
				let width = containerWidth + ((windowWidth-containerWidth)/2)
				document.querySelectorAll('[data-glide-container]').forEach(elem => {
					elem.style.width = width + 'px'
				})
				let images = document.querySelectorAll('.glide__slide > img')
				if( images.length > 0 ) {
					let image = images[0]
					this.container.style.height = image.clientHeight + 'px'
				}
				
			},

			hideCardPreview() {
				this.cardPreview = null
			},

			previewCard(url) {
				this.cardPreview = url
			},

			getOccasions() {
				axios.get('/api/occasions').then(response => {
					this.occasions = response.data.filter(o => {
						return o.slug !== 'other'
					})
					let occasion = this.selectedOccasion ? this.occasions.find(o => {
						return o.slug == this.selectedOccasion
					}) : this.occasions[0]
					this.selectOccasion(occasion)
				}).catch(error => {
					console.log(error)
				})
			},

			getCardTypes() {
				this.loading = true
				axios.get('/api/card-types', {
					params: {
						occasion: this.occasion
					}
				}).then(response => {
					this.cardTypes = response.data
				}).catch(error => {
					console.error(error)
				}).then(() => {
					this.initCarousel()
					setTimeout(() => {
						this.loading = false
						this.container.classList.remove('overflow-hidden')
						window.dispatchEvent(new Event('resize'))
					}, 500)
				})
			},

			selectOccasion(occasion) {
				this.loading = true
				this.occasion = occasion.slug
				this.getCardTypes()
			},
		}

	};
	
</script>