<template>

	<div>

		<loading v-if="loading"></loading>

		<template v-else>

			<div class="grid sm:grid-cols-2 md:grid-cols-3 gap-x-4 gap-y-12 lg:gap-y-16 lg:gap-x-16">
				<div v-for="post in posts">
					<blog-card :post="post"></blog-card>
				</div>
			</div>

			<div class="mt-10 text-center" v-if="posts.length >= 3">
				<a href="/content" class="inline-flex rounded-full items-center font-medium border text-white bg-primary-600 border-primary-600 text-base px-7 py-3 lg:px-9 lg:py-4 text-lg">View all</a>
			</div>
			
		</template>
		
	</div>

</template>

<script>

	import BlogCard from './BlogCard.vue'
	import Loading from '../../components/Loading.vue'
	

	export default {

		props: ['perPage'],

		components: {
			'blog-card': BlogCard,
			'loading': Loading,
		},

		data() {
			return {
				loading: false,
				posts: [],
				pageSize: this.perPage || 3,
			}
		},

		async mounted() {
			await this.getPosts()
		},

		methods: {

			async getPosts() {
				this.loading = true
				const response = await this.$prismic.client.query(
					[
						this.$prismic.predicate.any('document.type', ['blog', 'article']),
						'[not(document.id, "ZWT3VxAAACMA31f5")]'
					], {
						pageSize: this.pageSize,
						fetchLinks: 'category.name',
						orderings: {
							field: 'document.first_publication_date',
							direction: 'desc'
						}
					}
				)
				this.loading = false
				this.posts = response.results
			},
		},

		computed: {

			title() {
				return 'Freedom Mobiles Blog'
			}
		},

		// Format head tags
		head: {
			title: function() {
				return {
					inner: this.title
				}
			},
			meta: function() {
				return [{
					name: 'description',
					content: this.post.data.meta_description,
				}]
			},
		},
	};
	
</script>