<template>

	<div class="flex justify-end items-center space-x-2" :data-scroll="selector"  style="display: none">
		<button class="h-6 w-6 rounded-full flex justify-center items-center bg-primary-100" data-left :data-scroll="selector">
			<icon size="sm" class="stroke-primary-500" type="arrow-left"></icon>
		</button>
		<button class="h-6 w-6 rounded-full flex justify-center items-center bg-primary-100" data-right :data-scroll="selector">
			<icon size="sm" class="stroke-primary-500" type="arrow-right"></icon>
		</button>
	</div>
	
</template>

<script>

	import Icon from './Icon.vue'

	export default {

		props: ['selector'],

		components: {
			'icon': Icon
		},

	};
	
</script>